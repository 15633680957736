













import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { VueConstructor } from 'vue'
import { FiltersConfig, QueryParams } from '@movecloser/front-core/lib/contracts/filter-parser'

import { FilterParamConfig, NavigationItem } from '../../../../contexts'
import { FiltersHandlerMixin } from '../../../../support/mixins/FiltersHandler.mixin.vue'
import StructureConfigurable from '../../../../support/mixins/StructureConfigurable.mixin.vue'

import { FilterBadges } from '../../molecules/FilterBadges/FilterBadges.vue'
import { FilterIcon } from '../../icons/icons'

import {
  FilterLayout,
  filterLayoutsRegistry,
  FILTERS_COMPONENT_TYPE_KEY,
  FiltersComponentConfig, filtersComponentConfigMap
} from './Filters.config'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Filters>({
  name: 'Filters',
  components: { FilterBadges, FilterIcon },
  created () {
    this.config = this.getComponentConfig(FILTERS_COMPONENT_TYPE_KEY, filtersComponentConfigMap)
  }
})
export class Filters extends Mixins(FiltersHandlerMixin, StructureConfigurable) {
  @Prop({ required: true, type: Array })
  public params!: FilterParamConfig[]

  @Prop({ required: true, type: Object })
  public query!: QueryParams

  @Prop({ required: false, type: Array })
  public navData?: NavigationItem[]

  protected config!: FiltersComponentConfig

  protected filters: FiltersConfig | null = this.initFilters(this.params, this.query)

  public get pageComponentType (): VueConstructor {
    if (!(this.config.layout in filterLayoutsRegistry)) {
      return filterLayoutsRegistry[FilterLayout.Popover]
    }

    return filterLayoutsRegistry[this.config.layout]
  }

  public updateQuery (query: QueryParams, filters: FiltersConfig) {
    this.$emit('setQuery', query, filters)
  }

  @Watch('filters')
  private update () {
    this.$emit('setQuery', this.getQueryParams(this.filters, this.query), this.filters)
  }
}

export default Filters
