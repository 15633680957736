































































import { Component, Inject, Mixins, Prop, Watch } from 'vue-property-decorator'

import { FiltersConfig, QueryParams } from '@movecloser/front-core/lib/contracts/filter-parser'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../support'
import {
  FilterParamConfig,
  NavigationItem,
  TitleData
} from '../../../../contexts'
import { FiltersHandlerMixin } from '../../../../support/mixins/FiltersHandler.mixin.vue'

import { Drawers } from '../../../products/config/modals'

import { DrawerType, IDrawer } from '../../contracts/services'
import { FilterIcon } from '../../icons/icons'
import { FiltersDrawerProps } from '../../molecules/FiltersDrawer/FiltersDrawer.contracts'

import { Filters } from '../Filters'
import { FiltersWrapperProps } from './FiltersWrapper.contracts'
import { ListDisplayControls } from '../ListDisplayControls'
import { StructureConfigurable } from '../../../../support/mixins'
import {
  FILTERS_WRAPPER_COMPONENT_CONFIG_MAP,
  FILTERS_WRAPPER_COMPONENT_KEY,
  FiltersWrapperConfig
} from './FiltersWrapper.config'
import WindowScrollMixin from '../../mixins/windowScroll.mixin'
import { HeadingData } from '../../../../modules/partials/HeadingForm'
import TopBarMixin from '../../mixins/topBar.mixin'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<FiltersWrapper>({
  name: 'FiltersWrapper',
  components: { Filters, FilterIcon, ListDisplayControls },
  created () {
    this.config = this.getComponentConfig(FILTERS_WRAPPER_COMPONENT_KEY, { ...FILTERS_WRAPPER_COMPONENT_CONFIG_MAP })
  }
})
export class FiltersWrapper extends Mixins(FiltersHandlerMixin, StructureConfigurable,
  TopBarMixin, WindowScrollMixin) {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ required: true, type: Object })
  public readonly heading!: HeadingData

  @Prop({ required: false, type: Boolean })
  public readonly hideListControlParamsOnMobile?: boolean

  @Prop({ required: false, type: Boolean, default: true })
  public readonly listDisplayControlsInDrawer?: boolean

  @Prop({ required: false, type: String })
  public readonly mobileTriggerTitle?: string

  @Prop({ required: true, type: Array })
  public params!: FilterParamConfig[]

  @Prop({ required: true, type: Object })
  public query!: QueryParams

  @Prop({ type: Object, required: false })
  public title?: FiltersWrapperProps['title']

  @Prop({ required: false, type: Array })
  public navData?: FiltersWrapperProps['navData'] | null

  @Prop({ type: Number, required: false, default: null })
  public total?: FiltersWrapperProps['total'] | null

  protected config!: FiltersWrapperConfig

  protected filters: FiltersConfig | null = this.initFilters(this.params, this.query)
  public navItemsFilters: Array<NavigationItem[]> = []
  public filtersVisibility: boolean = true

  public get filtersParams (): FilterParamConfig[] {
    return this.params.filter(p => p.isAttribute === true)
  }

  public get areFiltersVisible (): boolean {
    return this.filtersVisibility
  }

  public get listControlParams (): FilterParamConfig[] {
    return this.params.filter(p => p.isAttribute !== true)
  }

  public get parentTitle (): TitleData | null {
    if (!this.title) {
      return null
    }
    return this.title
  }

  public get shouldRender (): boolean {
    return !!this.params.length
  }

  public onApplyFilters (newFilters: FiltersConfig) {
    this.filters = this.updateAllFilters(this.filters, newFilters)
  }

  public onVisibilityToggle (newValue: boolean): void {
    this.filtersVisibility = newValue
    this.$emit('setFiltersVisibility', newValue)
  }

  public get shouldImplementMobileHeading (): boolean {
    return this.getConfigProperty<boolean>('shouldImplementMobileHeading') && this.isMobile()
  }

  public get shouldAnimate (): boolean {
    return this.getConfigProperty<boolean>('shouldAnimate') && this.isMobile()
  }

  /**
   * Composed filters from category children, that should behave like links
   */
  public get composedNavLinks () {
    const links = []

    if (this.navItemsFilters[0]) {
      for (const item of Object.values(this.navItemsFilters[0])) {
        if (item.label) {
          links.push({
            target: item.target.toString(),
            label: item.label?.toString()
          })
        }
      }
    }

    return links
  }

  private onFiltersUpdate (_query: QueryParams, newFilters: FiltersConfig) {
    this.filters = this.updateAllFilters(this.filters, newFilters)
  }

  private openMobileFilters () {
    const params = this.listDisplayControlsInDrawer ? this.params : this.filtersParams

    const payload: FiltersDrawerProps = {
      clearFilters: this.clearFilters,
      filters: this.filters ?? {},
      hideListControlParamsOnMobile: this.hideListControlParamsOnMobile,
      navItemsFilters: this.composedNavLinks,
      onApply: this.onApplyFilters,
      params,
      removeFilter: this.removeFilter,
      title: this.parentTitle,
      navData: this.navData
    }
    this.$container?.get<IDrawer>(DrawerType).open(Drawers.Filters, payload)

    // this.drawerConnector.open(Drawers.Filters)
  }

  @Watch('filters')
  private update () {
    this.$emit('setQuery', this.getQueryParams(this.filters, this.query))
  }
}

export default FiltersWrapper
