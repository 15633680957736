
import { VueConstructor } from 'vue'

import ClosableControls from './partials/ClosableControls.vue'
import { FilterLayout } from '../Filters/Filters.config'
import PopoverControls from './partials/PopoverControls.vue'

export enum ToggleFilterIcon {
  Hide = 'hide',
  Show = 'show'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const listDisplayControlsComponentsRegistry: Record<string, VueConstructor> = {
  [FilterLayout.Accordion]: ClosableControls,
  [FilterLayout.Popover]: PopoverControls
}
