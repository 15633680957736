
import { VueConstructor } from 'vue'

import FilterAccordion from './partials/FilterAccordion/FilterAccordion.vue'
import FilterPopoverList from './partials/FilterPopoverList/FilterPopoverList.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum FilterLayout {
  Accordion = 'accordion',
  Popover = 'popover'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const filterLayoutsRegistry: Record<string, VueConstructor> = {
  [FilterLayout.Accordion]: FilterAccordion,
  [FilterLayout.Popover]: FilterPopoverList
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface FiltersComponentConfig {
  layout: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FILTERS_COMPONENT_TYPE_KEY = 'Filters'

/**
 * Component configuration for app
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const filtersComponentConfigMap = {
  layout: 'popover'
}
